// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-development-team-js": () => import("./../../../src/pages/about/development-team.js" /* webpackChunkName: "component---src-pages-about-development-team-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-project-partners-js": () => import("./../../../src/pages/about/project-partners.js" /* webpackChunkName: "component---src-pages-about-project-partners-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interactives-carbon-calculator-js": () => import("./../../../src/pages/interactives/carbon-calculator.js" /* webpackChunkName: "component---src-pages-interactives-carbon-calculator-js" */),
  "component---src-pages-interactives-interactives-and-models-js": () => import("./../../../src/pages/interactives/interactives-and-models.js" /* webpackChunkName: "component---src-pages-interactives-interactives-and-models-js" */),
  "component---src-pages-introduction-to-units-js": () => import("./../../../src/pages/introduction-to-units.js" /* webpackChunkName: "component---src-pages-introduction-to-units-js" */),
  "component---src-pages-nasa-index-js": () => import("./../../../src/pages/nasa/index.js" /* webpackChunkName: "component---src-pages-nasa-index-js" */),
  "component---src-pages-teacher-resources-index-js": () => import("./../../../src/pages/teacher-resources/index.js" /* webpackChunkName: "component---src-pages-teacher-resources-index-js" */),
  "component---src-pages-teacher-resources-program-materials-js": () => import("./../../../src/pages/teacher-resources/program-materials.js" /* webpackChunkName: "component---src-pages-teacher-resources-program-materials-js" */),
  "component---src-pages-teacher-resources-student-materials-js": () => import("./../../../src/pages/teacher-resources/student-materials.js" /* webpackChunkName: "component---src-pages-teacher-resources-student-materials-js" */),
  "component---src-pages-teacher-resources-teacher-materials-js": () => import("./../../../src/pages/teacher-resources/teacher-materials.js" /* webpackChunkName: "component---src-pages-teacher-resources-teacher-materials-js" */),
  "component---src-pages-teacher-support-index-js": () => import("./../../../src/pages/teacher-support/index.js" /* webpackChunkName: "component---src-pages-teacher-support-index-js" */),
  "component---src-pages-unit-1-1-0-overview-js": () => import("./../../../src/pages/unit-1/1.0-overview.js" /* webpackChunkName: "component---src-pages-unit-1-1-0-overview-js" */),
  "component---src-pages-unit-1-1-1-carbon-fizz-js": () => import("./../../../src/pages/unit-1/1.1-carbon-fizz.js" /* webpackChunkName: "component---src-pages-unit-1-1-1-carbon-fizz-js" */),
  "component---src-pages-unit-1-1-2-carbon-from-the-past-js": () => import("./../../../src/pages/unit-1/1.2-carbon-from-the-past.js" /* webpackChunkName: "component---src-pages-unit-1-1-2-carbon-from-the-past-js" */),
  "component---src-pages-unit-1-1-3-carbon-forcing-js": () => import("./../../../src/pages/unit-1/1.3-carbon-forcing.js" /* webpackChunkName: "component---src-pages-unit-1-1-3-carbon-forcing-js" */),
  "component---src-pages-unit-1-1-4-global-connections-js": () => import("./../../../src/pages/unit-1/1.4-global-connections.js" /* webpackChunkName: "component---src-pages-unit-1-1-4-global-connections-js" */),
  "component---src-pages-unit-1-1-5-core-connections-js": () => import("./../../../src/pages/unit-1/1.5-core-connections.js" /* webpackChunkName: "component---src-pages-unit-1-1-5-core-connections-js" */),
  "component---src-pages-unit-2-2-0-overview-js": () => import("./../../../src/pages/unit-2/2.0-overview.js" /* webpackChunkName: "component---src-pages-unit-2-2-0-overview-js" */),
  "component---src-pages-unit-2-2-1-moving-carbon-js": () => import("./../../../src/pages/unit-2/2.1-moving-carbon.js" /* webpackChunkName: "component---src-pages-unit-2-2-1-moving-carbon-js" */),
  "component---src-pages-unit-2-2-2-exploring-limits-js": () => import("./../../../src/pages/unit-2/2.2-exploring-limits.js" /* webpackChunkName: "component---src-pages-unit-2-2-2-exploring-limits-js" */),
  "component---src-pages-unit-2-2-3-the-breathing-biosphere-js": () => import("./../../../src/pages/unit-2/2.3-the-breathing-biosphere.js" /* webpackChunkName: "component---src-pages-unit-2-2-3-the-breathing-biosphere-js" */),
  "component---src-pages-unit-2-2-4-carbon-cycling-js": () => import("./../../../src/pages/unit-2/2.4-carbon-cycling.js" /* webpackChunkName: "component---src-pages-unit-2-2-4-carbon-cycling-js" */),
  "component---src-pages-unit-2-2-5-earth-takes-a-breath-js": () => import("./../../../src/pages/unit-2/2.5-earth-takes-a-breath.js" /* webpackChunkName: "component---src-pages-unit-2-2-5-earth-takes-a-breath-js" */),
  "component---src-pages-unit-3-3-0-overview-js": () => import("./../../../src/pages/unit-3/3.0-overview.js" /* webpackChunkName: "component---src-pages-unit-3-3-0-overview-js" */),
  "component---src-pages-unit-3-3-1-your-temperature-connections-js": () => import("./../../../src/pages/unit-3/3.1-your-temperature-connections.js" /* webpackChunkName: "component---src-pages-unit-3-3-1-your-temperature-connections-js" */),
  "component---src-pages-unit-3-3-2-testing-forcings-js": () => import("./../../../src/pages/unit-3/3.2-testing-forcings.js" /* webpackChunkName: "component---src-pages-unit-3-3-2-testing-forcings-js" */),
  "component---src-pages-unit-3-3-3-future-forcings-js": () => import("./../../../src/pages/unit-3/3.3-future-forcings.js" /* webpackChunkName: "component---src-pages-unit-3-3-3-future-forcings-js" */),
  "component---src-pages-unit-3-3-4-it-starts-at-home-js": () => import("./../../../src/pages/unit-3/3.4-it-starts-at-home.js" /* webpackChunkName: "component---src-pages-unit-3-3-4-it-starts-at-home-js" */),
  "component---src-pages-unit-3-3-5-carbon-claims-js": () => import("./../../../src/pages/unit-3/3.5-carbon-claims.js" /* webpackChunkName: "component---src-pages-unit-3-3-5-carbon-claims-js" */)
}

